import React from "react";
import "./Cards.css"
import AppleLogo from "./components/AppleLogo";
function Card() {
    return(
        <>
        <div className="container">
            <div className="card" data-aos="zoom-in" data-aos-duration="1000">
                <div className="h">
                    <h3>
                        🌍 Import-<span>Export</span>
                    </h3>
                </div>
                <hr />
                <div className="passage">
                <p>
                Cressa Gadgets is an Import- Export partner of STM brand. <span>products and services from manufacturer to wholesaler and wholesaler to retail outlets.</span>
                </p>
                </div>
            </div>
            <div className="card" data-aos="zoom-in" data-aos-duration="2000" >
                <div className="h">
                    <h3>
                    🤝 B2B <span>Trading</span>
                    </h3>
                    
                </div>
                <hr />
                <div className="passage">
                <p>
                Cressa Gadgets offers a platform to enable seamless flow of products and services from manufacturer to wholesaler and wholesaler to retail outlets.
                </p>
                </div>

            </div>
            <div className="card" data-aos="zoom-in" data-aos-duration="3000">
                <div className="h">
                    <h3>
                    🛍️ Retail<span >.</span>
                    </h3>
                    
                </div>
                <hr />
                <div className="passage">
                <p>We are an affiliated Apple premium reseller with our sister company, managing 6 premium Apple stores and multi- brand stores in Delhi-NCR.</p>
                </div>
                
                
            </div>  
            <div className="card" data-aos="zoom-in" data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
                <div className="h">
                    
                    <h3>
                        <AppleLogo />
                        Authorised <span>Partner</span>
                    </h3>

                </div>
                <hr />
                <div className="passage">
                <p>We are authorized DPP partner of Apple. <span>lorem ipsum lodor ispum yrew brew coffee no xyz hell yaa if u are We also have a dedicated call center team for telephonic support.</span> </p>
                </div>
            </div>
            <div className="card" data-aos="zoom-in" data-aos-duration="2000">
                <div className="h">
                    <h3>
                     🛎️ Services<span>.</span>
                    </h3>

                </div>
                <hr />
                <div className="passage">
                <p>We have an in-house dedicated team for repairs, quality check purpose and are associated with service centers PAN India to provide after sales service to our customers. We also have a dedicated call center team for telephonic support.</p>
                </div>
            </div>

        </div>
        </>
    )

}

export default Card