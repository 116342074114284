import React from "react";
import "./Core.css"
import service from "../Image/service.png"
import partner from "../Image/partner.png"
import diverse from "../Image/diversity.png"
import passion from "../Image/passion.png"
import integrity from "../Image/integrity.png"
function values(){
    return(
        <>
        <div className="core" data-aos="fade-right" data-aos-duration="2000">
            <hr />
            <h2>Our Core Values</h2>
            <hr />
        </div>
        <div className="container1">
            <div className="card1" data-aos="zoom-in" data-aos-duration="2500">
                <div className="h1">
                    <h3>💼 Customer Service</h3>
                    <hr />
                    <div className="passage1">
                        <img src={service} alt="ser" />
                    </div>
                </div>
            </div>
            <div className="card1" data-aos="zoom-in" data-aos-duration="2500">
                <div className="h1">
                    <h3>🤝 Partnership</h3>
                    <hr />
                    <div className="passage1">
                        <img src={partner} alt="part" />
                    </div>
                </div>
            </div>
            <div className="card1" data-aos="zoom-in" data-aos-duration="2500">
                <div className="h1">
                    <h3>🎭 Diversity</h3>
                    <hr />
                    <div className="passage1">
                        <img src={diverse} alt="L" />
                    </div>
                </div>
            </div>
            <div className="card1" data-aos="zoom-in" data-aos-duration="2500">
                <div className="h1">
                    <h3>🚀 Passion</h3>
                    <hr />
                    <div className="passage1">
                        <img src={passion} alt="passionatePerson" />
                    </div>
                </div>
            </div>
            <div className="card1" data-aos="zoom-in" data-aos-duration="2500">
                <div className="h1">
                    <h3>💎 Integrity</h3>
                    <hr />
                    <div className="passage1">
                        <img src={integrity} alt="integrity" />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default values