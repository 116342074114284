import React from "react";
import ceo_img from "../../Image/ceo_img.jpg";

import "./Ceo_Message.css";
function Message() {
  return (
    <>
      <div className="proj_containar_section">
        <div className="proj_left">
          <h3>Message from Director</h3>
          <p>
          <span>"O</span>ur passion for electronic products and accessories in  India, US, UK, and UAE, has helped us achieve the access to 10 lac+ customer base through our retail networks and marketplace platforms. The group has reported a track record of consistent growth over the years. We take pride in being a value player by offering compelling services to customers enabled by our direct sourcing and an asset-light business model. Our focus on expanding our company’s footprint along with widening the product portfolio has broad-based market share gains and we are confident of continuing this momentum.<br></br><div></div>
          As we look ahead, on behalf of the Board of Directors, I take this opportunity to express my sincere appreciation to our Customers, Partners and Employees for your support and trust. I seek your continued support to grow and progress of our Company to greater heights. <span>"</span><br></br>
          </p>
          
        </div>
        <div className="proj_right">
          <div className="circular-image">
          <img src={ceo_img} alt="Ceo Img" />
          <figcaption>Abhijeet Goswami <br></br> Co-Founder, Director</figcaption>
          </div>
        </div>
      </div>
    </>
  );
}

export default Message;
