import { AiOutlineHome } from 'react-icons/ai';
import { BiConversation } from 'react-icons/bi';
import { TfiControlForward } from 'react-icons/tfi';
import { RxDashboard } from 'react-icons/rx';

const my_navbar_data = [
  {
    index: 1,
    nav_link: `/#home_page`,
    navbar_name: 'Home',
    nav__icon: AiOutlineHome,
    bc: '#00a5ee',
  },
  {
    index: 2,
    nav_link: `/#about_us`,
    navbar_name: 'About Us',
    nav__icon: TfiControlForward,
  },
  {
    index: 3,
    nav_link: '/#xyz',
    navbar_name: 'Message from Director',
    nav__icon: RxDashboard,
  },
  {
    index: 4,
    nav_link: '/gallery',
    navbar_name: 'Gallery',
    nav__icon: RxDashboard,
  },
  {
    index: 5,
    nav_link: `/#contact_page`,
    navbar_name: 'Contact Us',
    nav__icon: BiConversation,
  },
];

export default my_navbar_data;
