const personal_info_data = [
  {
    index: 1,
    section_two: [
      {
        index: 1,
        data_type: "Phone",
        data_value: "+91 7065761941",
        icon_class: "fa-solid fa-phone-flip",
        ic_color: "#e64a19",
      },
      {
        index: 2,
        data_type: "Email",
        data_value: "cressagadgetspvtltd@gmail.com",
        icon_class: "fa-solid fa-envelope",
        ic_color: "#00838F",
      },
      {
        index: 3,
        data_type: "Address",
        data_value: "Cressa Gadgets Pvt Ltd 438, Patparganj Industrial Area, Delhi, 110092",
        icon_class: "fa-sharp fa-solid fa-location-dot",
        ic_color: "rgb(34 119 0)",
      },
    ],
  },
];

export default personal_info_data;
