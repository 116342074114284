const social_media_data = [
  {
    index: 1,
    icon_class: "fas fa fa-facebook-square",
    where_to_go_link:
      "https://www.facebook.com",
  },

  {
    index: 2,
    icon_class: "fas fa fa-instagram",
    where_to_go_link: "https://www.instagram.com/cressagroup",
  },
  {
    index: 3,
    icon_class: "fas fa fa-linkedin",
    where_to_go_link: "https://www.linkedin.com/",
  },
];

export default social_media_data;
