import React from "react";
import BottomNav from "./components/BottomNav";
import "./Footer.css";
function Footer() {
  return (
    <div>
      <div className="footer_section">
        <BottomNav />
        <div className="line"></div>
        <h2>CressaGroup.com</h2>
         <div className="credits"> 
          <p>Image/Art Credits : </p>
          <a href="https://www.freepik.com/free-vector/team-online-store-working-with-customer_4530381.htm#query=electronics%20distribution&position=1&from_view=search&track=ais">Image by katemangostar</a> on Freepik
          <a href="https://www.freepik.com/free-photo/man-using-digital-tablet-psd-mockup-smart-technology_16251062.htm#query=technology&position=39&from_view=search&track=sph">Image by rawpixel.com</a> on Freepik 
          <a href="https://www.freepik.com/free-vector/solidarity-concept-illustration_14562369.htm#query=diversity&position=3&from_view=search&track=sph">Image by storyset</a> on Freepik 
          <a href="https://www.freepik.com/free-vector/successful-business-man-holding-trophy_11879373.htm#query=passion&position=1&from_view=search&track=sph">Image by jcomp</a> on Freepik
          <a href="https://www.freepik.com/free-vector/partnership-concept-illustration_13766133.htm#query=partnership&position=2&from_view=search&track=sph">Image by storyset</a> on Freepik
          <a href="https://www.freepik.com/free-vector/call-center-telephonist-flat-design_4750398.htm#query=customer%20service%20square&position=1&from_view=search&track=ais">Image by pikisuperstar</a> on Freepik
          Image by <a href="https://www.freepik.com/free-vector/hand-drawn-flat-design-international-trade_19962385.htm#query=import%20export&position=0&from_view=search&track=ais">Freepik</a>
          <a href="https://www.freepik.com/free-vector/isometric-laptop-with-shopping-cart-keypad_3297746.htm#query=ecommerce&position=5&from_view=search&track=sph">Image by katemangostar</a> on Freepik
        
        </div> 
      </div>
    </div>
  );
}

export default Footer;
