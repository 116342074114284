import React from "react";
import AOS from 'aos';
import ParticlesBackground from "./components/ParticlesBackground";
import 'aos/dist/aos.css';
import "./About.css";
import ecomm from "../Image/ecomm2.jpg"
import distr from "../Image/19368.jpg"

function About() {
  return (
    
    <>
    
    <div className="about_me" id="about_us">
      <div className="container_01">
        <div className="info">
        
          <div className="animationh" data-aos="fade-right" data-aos-duration="2000" data-aos-anchor-placement="bottom-bottom">
          <h2>
            About <span>Us</span>.
          </h2>
          <hr className="lineP" />
          </div>
          <div className="pass" data-aos="fade-left" data-aos-duration="3000" >        
          <p >
          Started in 2006, Cressa Gadgets Private Limited is India’s leading electronics platform for E-commerce, Distribution, Import- Export, Services, B2B and Retail.

          </p>
          <br></br>
          <p >
          We are also an authorized partner of Apple, STM, Saregama, Belkin, Fireboltt.
            </p>
            </div>
          </div>
      </div>
      <br></br>

    </div>
    <div className="ecommQ" >
    <div className="Ecommerce">
      <div className="ecommHead" data-aos="zoom-in-left" data-aos-duration="2000" data-aos-anchor-placement="center-bottom">
       
      <h2>
        Ecommerce<span>.</span>
      </h2>
      <hr />
      </div>
      <div data-aos="zoom-in-left" data-aos-duration="2000" > 
      <p>
      We are one of the top electronics e-commerce partners of Amazon, Flipkart and other leading marketplaces in India. We deal into different categories like Mobiles, Laptops, Tablets, Appliances and other Electronic Accessories.
      </p>
      <br></br>
      <span>
        <p>Cressa Gadgets Private Limited serves over 4,500 cities and 15000+ pin codes in India. We also operate globally in countries like US, UK and UAE. </p>
      </span>
      </div>
      
    </div>
    <div className="imgL" data-aos="flip-right" data-aos-duration="2000" >
      <img src={ecomm} alt="Ecommerce" />
    </div>
    </div>  
    <div className="white"><span></span></div>
    <div className="DisQ">
    <div className="Distribution">
      <div>
      <div className="DisH" data-aos="fade-left" data-aos-duration="2000" data-aos-anchor-placement="center-bottom">
      <hr />
      <h2>
        Distribution<span>.</span>
      </h2></div>
      <div className="passD" data-aos="fade-left" data-aos-duration="2000" data-aos-anchor-placement="center-bottom">
      <p>We are an authorized distributor of:</p>
      <ul>
      <li>Apple</li>
      <li>Nokia</li>
      <li>Motorola</li>
      <li>Fireboltt</li>
      <li>Saregama</li>
      <li>Lava</li>
      <li>Belkin</li>
      <li>Logitech</li>
      <li>Stuffcool</li>
    </ul>
    </div>
    </div>
    <div className="imgD" data-aos="flip-right" data-aos-duration="2000">
    <img src={distr}></img></div>
    </div>
    </div>
    </>

  );
}

export default About;
