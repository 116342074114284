import React from 'react'
import './ContactPage.css'
import contactImg from '../Image/contactPage.jpg'

function Contact() {
    return (
        <div className='Contact_us' id="contact_page">

            <img src={contactImg} alt="Contact Info" />

        </div>
    )
}

export default Contact;