import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Frontpage from './pages/Frontpage';
import About from './pages/About';
import TopBrands from './pages/brands';
import CeoMessage from './pages/CEO';
import Contact from './pages/ContactPage'
import Navbar from './pages/Navbar';
import Footer from './pages/Footer';
import Card from './pages/Cards';
import Values from './pages/Core';
import Gallery from './pages/Gallery';

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>

              <Frontpage />
              <About />
              <Card />
              <Values />
              <TopBrands />
              <CeoMessage />
              <Contact />
              <Footer />
            </>
          }
        />
        <Route path="gallery" element={<Gallery />} />
        
      </Routes>
    </>
  );
};

export default App;
