import React from "react";
import brandImage from '../Image/brands.png'


import "./brands.css";
function TopBrands() {
  return (
    <div className="brands_me">
    
       <img src={brandImage}
      alt="mid"
       ></img>
     
      <br></br>

    </div>
  );
}

export default TopBrands;
