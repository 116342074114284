import React from 'react';


import './Gallery.css';


const Gallery = () => {


  return (
    <div className='Coming'>
        <h2>Coming Soon..</h2>
    </div>
  );
};

export default Gallery;