import React from 'react';
import SocialMediaLinks from './components/SocialMediaLinks';
import SvgBackground from './components/SvgBackground';
import './Frontpage.css';
import Particle from './components/Particle';


function Frontpage() {
  return (
    <>
    
      <div className="profile-container" id="home_page">
        <div className="profile-parent">
        
          <div className="profile-details">
            <div className="profile-details-name">
              <p className="primary-text">
              Welcome to <br></br>
                <span className="highlighted-text">Cressa Gadgets</span>
              </p>
            </div>
            <div className="profile-details-role">
              <span className="primary-text">
                
                <span className="profile-role-tagline">
                Smart Gadgets for Smart Generation.
                </span>
              </span>
              <div className="colz">
                <SocialMediaLinks />
              </div>
            </div>

            
          </div>
          
        </div>
        
      </div>
    </>
  );
}

export default Frontpage;
