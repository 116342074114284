import React from 'react';
import Message from './components/Ceo_Message';

import './CEO.css';


const CeoMessage = () => {


  return (
    <div>
      <div className="work_page" id="xyz">
        <div className="proj_component">
          <Message />
        </div>
       
       
      </div>
    </div>
  );
};

export default CeoMessage;
